.l-pdp {
	.b-breadcrumbs-item:last-child {
		@include media(md-up) {
			.b-breadcrumbs-link {
				color: $color-grey44;
				cursor: default;
				pointer-events: none;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	.h-country_selector {
		display: none;
	}
}
