.b-product_carousel {
	&-thumbnails {
		@include g-carousel(-vertical, 480px);

		float: left;
		max-width: 100px;
		padding-right: rh(7);

		@include media(lg-up) {
			width: 13%;
		}

		@include media(md) {
			width: 30%;
		}

		@include media(sm) {
			/* stylelint-disable */

			display: none !important; //to override slick slider native styles
			/* stylelint-enable */
		}

		&.slick-initialized {
			overflow: hidden;
		}

		&.m-long {
			.slick-list {
				&::after {
					background: $color-white;
					border-top: 1px solid $color-divider;
					bottom: 0;
					content: '';
					height: 35px;
					left: 0;
					position: absolute;
					width: 100%;
				}
			}
		}

		.h-zoom-activated & {
			left: 30px;
			position: fixed;
			top: 40px;
			z-index: 1502;
		}

		&_hr {
			max-width: 100%;
			padding: rh(7) 0 0;
			width: 100%;
		}
	}

	&-thumb {
		background: $color-white;
		border: solid 1px transparent;
		border-radius: $global-radius;
		cursor: pointer;
		height: 62px;
		min-width: 62px;
		overflow: hidden;
		padding: rh(1);
		transition: border-color $motion-ease;

		img {
			height: 100%;
			object-fit: contain;
			width: 100%;
		}

		&:hover {
			border-color: $color-brand88;
		}

		.slick-current & {
			border-color: $color-brand88;
			cursor: default;

			.b-theme_hmv & {
				border-color: var(--color-neutral-700);
			}
		}

		.b-product_set_detail & {
			height: auto;
			min-width: initial;
		}
	}

	&-thumb ~ &-thumb {
		margin-bottom: rh(3);
	}

	&-main {
		@include g-carousel;

		float: left;

		@include media(lg-up) {
			width: 87%;
		}

		@include media(md) {
			width: 70%;
		}

		@include media(sm) {
			width: 100%;
		}

		.slick-slide {
			padding: 0 2px;
		}

		.slick-arrow {
			align-items: center;
			border: solid 1px $color-brand97;
			border-radius: $global-radius;
			box-shadow: $depth-1;
			color: transparent;
			cursor: pointer;
			display: flex;
			font-size: 1px;
			height: 50px;
			justify-content: center;
			opacity: 0.9;
			overflow: hidden;
			position: absolute;
			text-align: center;
			top: 50%;
			transform: translateY(-50%);
			transition: 0.3s ease;
			transition-property: border-color;
			width: 50px;
			z-index: z(components, slick-slider, arrows);

			.b-theme_hmv & {
				border-color: var(--color-brand76);
			}

			&:hover {
				border-color: $color-brand;
			}

			.b-product_set_detail & {
				background-size: 10px;
				height: 30px;
				width: 30px;
			}
		}

		.slick-arrow.slick-prev {
			left: 0;

			@include media(sm) {
				background-size: 8px 14px;
			}
		}

		.slick-arrow.slick-next {
			right: 0;

			@include media(sm) {
				background-size: 8px 14px;
			}
		}

		.slick-dots {
			display: flex;
			justify-content: center;
			margin: rh(5 0 8);

			li ~ li {
				margin-left: rh(4);
			}

			button {
				background-color: $color-grey91;
				border: 2px solid $color-white;
				border-radius: 50%;
				color: transparent;
				cursor: pointer;
				display: block;
				font-size: 1px;
				height: 12px;
				line-height: 8;
				overflow: hidden;
				padding: 0;
				transition-duration: 0.2s;
				transition-property: background-color, border-color;
				transition-timing-function: ease-in;
				width: 12px;

				&:hover {
					background-color: $color-brand;
					border-color: $color-brand;
				}
			}

			.slick-active button {
				background-color: $color-brand;
				border-color: $color-brand;
			}
		}

		.b-product_set_detail & {
			@include media(lg-up) {
				width: 100%;
			}

			@include media(md) {
				width: 100%;
			}
		}
	}

	&-image {
		@include g-carousel(_slide);
		@include g-product-image;

		min-height: 410px;

		@include media(md-down) {
			min-height: 300px;
		}

		@include media(lg-up) {
			min-height: 622px;
		}

		.slick-slide & img {
			@include g-product-image(_img);
		}

		.b-product_set_detail & {
			@include media(lg-up) {
				min-height: 410px;
			}
		}

		.b-product_set_detail-wrapper & {
			padding-bottom: 0;
		}
	}

	&::after {
		clear: both;
		content: '';
		display: block;
		visibility: hidden;
	}
}
