/*
	<div class="b-tabs"
		data-component="global/Tabs">

		<div class="b-tabs-head">
			<ul class="b-tabs-list" data-js-tabs>
				<li class="b-tabs-control" data-js-tab-item="1">
					<span class="b-tabs-control_name">Item 1 Title</span>
				</li>
				<li class="b-tabs-control" data-js-tab-item="2">
					<span class="b-tabs-control_name">Item 2 Title</span>
				</li>
				<li class="b-tabs-control" data-js-tab-item="3">
					<span class="b-tabs-control_name">Item 3 Title</span>
				</li>
				<li class="b-tabs-control" data-js-tab-item="4">
					<span class="b-tabs-control_name">Item 4 Title</span>
				</li>
			</ul>
		</div>

		<div class="b-tabs-content" data-js-tabscontent>
			<div class="b-tabs-item" data-js-tab-content="1">Item 1 Content</div>
			<div class="b-tabs-item" data-js-tab-content="2">Item 2 Content</div>
			<div class="b-tabs-item" data-js-tab-content="3">Item 3 Content</div>
			<div class="b-tabs-item" data-js-tab-content="4">Item 4 Content</div>
		</div>
	</div>
*/

@mixin g-tabs(
	$_: root
) {
	@if $_ == root {
		border: solid 1px $color-brand94;
		border-radius: $global-radius;
		box-shadow: $depth-2;

		.b-theme_hmv & {
			border: 0;
		}
	}

	@if $_ == _tab_list {
		background-color: $color-brand97;
		border-bottom: 1px solid $color-brand94;
		display: flex;

		.b-theme_hmv & {
			background-color: var(--color-neutral-900);
			border: 0;
		}
	}

	@if $_ == _tab {
		background-color: transparent;
		border-left: 1px solid $color-brand94;
		color: $color-brand76;
		cursor: pointer;
		display: flex;
		font-size: 16px;
		font-weight: bold;
		margin-bottom: -1px;
		padding: rh(4 7);
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		transition: $motion-ease;
		transition-property: background-color, color;

		@include media(md) {
			font-size: 12px;
		}

		.b-theme_hmv & {
			color: var(--color-neutral-400);
			border: 0;
			padding: rh(6 8);
		}

		&:hover {
			text-decoration: none;
		}
	}

	@if $_ == _tab_active {
		background-color: $color-white;
		box-shadow: 0 1px 0 0 $color-white;
		color: $color-brand;
		cursor: default;
	}

	@if $_ == _tab_first {
		border-left: none;
		border-radius: $global-radius 0 0 0;
	}

	@if $_ == _content {
		display: none;
		padding: rh(7 8 9);
	}

	@if $_ == _content_shown {
		display: block;
	}
}
