.b-product_social {
	&-label {
		font-size: 14px;
		margin-bottom: rh(3);

		.b-theme_rle & {
			font-weight: 500;
		}
	}

	&-set {
		display: flex;
	}

	&-item {
		height: 40px;
		width: 40px;
	}

	&-item ~ &-item {
		margin-left: rh(3);
	}

	&-link {
		align-items: center;
		background-color: $color-white;
		border-radius: 50%;
		box-shadow: $depth-1;
		color: $color-brand;
		display: flex;
		fill: currentcolor;
		height: 40px;
		justify-content: center;
		line-height: 0;
		transition-duration: 0.2s;
		transition-property: background-color, color;
		transition-timing-function: ease-in;
		width: 40px;

		.b-theme_hmv & {
			background-color: var(--color-brand);
			color: var(--color-neutral-1000);
			box-shadow: none;
		}

		&:hover {
			background-color: $color-brand;
			color: $color-white;

			.b-theme_hmv & {
				background-color: var(--color-brand64);
				color: var(--color-neutral-1000);
			}
		}
	}
}
