.b-product_primary {
	display: flex;

	@include media(md-up) {
		justify-content: space-between;
	}

	@include media(sm) {
		flex-direction: column;
	}

	&-section.m-section1 {
		@include media(lg-up) {
			padding-right: rh(10);
			width: 67%;

			.b-product_set_detail & {
				max-width: rh(132);
			}

			.b-product_set_detail-wrapper & {
				.b-product_set_detail & {
					max-width: 35%;
				}
			}
		}

		@include media(md) {
			min-width: 62%;
			padding-right: rh(7);
			width: 67%;

			.b-product_set_detail & {
				max-width: rh(132);
				min-width: initial;
			}
		}
	}

	&-section {
		&.m-section2 {
			@include media(md-up) {
				max-width: 352px;
				min-width: 277px;
				width: 33%;

				.b-product_set_detail-wrapper & {
					flex: 1;
					max-width: initial;
				}
			}

			@include media(sm) {
				margin-top: rh(10);
			}
		}

		&_wide {
			&.m-section2 {
				flex: 0 1 auto;
				max-width: initial;
				width: initial;
			}
		}
	}

	&-zoom {
		display: flex;
		justify-content: center;
		padding-top: rh(5);

		@include media(lg-up) {
			margin-left: 15%;
			width: 85%;
		}

		@include media(md) {
			margin-left: 30%;
			width: 70%;
		}

		@include media(sm) {
			display: none;
		}
	}

	&-zoom_btn {
		align-items: center;
		background: none;
		border: 0;
		color: $color-brand;
		cursor: pointer;
		display: flex;
		font-family: $font-sans;
	}

	&-zoom_icon {
		color: $color-brand76;
		margin-right: rh(2);
	}

	&-zoom_btn:hover &-zoom_text {
		text-decoration: underline;
	}

	&-related_items {
		@include media(sm) {
			display: none;
		}
	}

	&:not(.b-product_primary-main_set_slider) {
		.b-product_set_detail & {
			justify-content: flex-end;
			margin-bottom: 20px;
		}
	}
}
