.b-product_store_availability {
	&-title {
		color: $color-green;
		font-size: 16px;
		font-weight: bold;
		margin-bottom: rh(7);
		text-align: center;
		text-transform: uppercase;
	}

	&-tile {
		border: 1px solid $color-brand94;
		border-radius: $global-radius;
		box-shadow: $depth-1;
		display: flex;
		flex-wrap: wrap;
		font-size: 14px;
		justify-content: space-between;
		margin-top: rh(4);
		padding: rh(5 5 6);
	}

	.b-radio {
		min-width: 40%;
	}

	/* stylelint-disable */
	.b-radio input[type=radio] + label {
		font: 14px/1 $font-sans;
		font-weight: bold;

		.b-theme_rle & {
			font-weight: 500;
		}
	}
	/* stylelint-enable */
	&-location {
		align-items: center;
		color: $color-brand76;
		display: flex;
		justify-content: flex-end;
		min-width: 30%;
	}

	&-distance {
		color: $color-black;
		font-weight: bold;
		margin-left: rh(1);
	}

	&-message {
		font-weight: bold;
		margin: rh(4 0 0 7);
		min-width: 40%;
		text-transform: uppercase;

		&.m-in_stock {
			color: $color-green;
		}

		&.m-low_stock {
			color: $color-orange;
		}

		&.m-out_of_stock {
			color: $color-red;
		}

		&.m-status_soldout {
			color: $color-red;
		}

		.b-theme_hmv & {
			color: var(--color-brand);
		}
	}

	&-direction {
		background: none;
		border: 0;
		color: $color-brand;
		cursor: pointer;
		margin-top: rh(3);
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	&-select_other {
		margin: rh(6 0 10);
	}

	&-other_label {
		margin-bottom: rh(6);
		text-align: center;
	}
}
