.b-product_related {
	margin-bottom: rh(-4);

	&-tiles {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: rh(10);
	}

	&-tile {
		max-width: 25%;
		min-width: 25%;

		picture {
			@include g-product-image;
		}

		img {
			@include g-product-image(_img);
		}
	}

	&-plus {
		color: $color-brand76;
		display: none;
		font-size: 24px;
		font-weight: bold;
		padding: rh(0 4);

		@include media(md) {
			padding: rh(0 2);
		}

		@include media(sm) {
			padding: rh(0 1);
		}
	}

	&-tile + &-plus {
		display: block;
	}

	&-checkboxes {
		display: flex;
		flex-direction: column;
	}

	&-checkbox {
		margin: rh(1) 0;
	}

	&-checkbox_link {
		color: $color-black;
		display: flex;
		flex-wrap: wrap;
		text-decoration: none;
	}

	&-checkbox_label {
		cursor: default;
		font-size: 16px;
		width: 100%;
	}

	&-checkbox_link &-checkbox_label {
		cursor: pointer;
	}

	&-price {
		display: block;
		font-weight: bold;
	}

	&-bottom {
		display: flex;
		margin-top: rh(4);
		padding-top: rh(7);
		position: relative;

		@include media(lg-up) {
			justify-content: space-between;
		}

		@include media(md-down) {
			flex-direction: column;
		}

		&::before {
			border-top: 1px solid rgba($color-blue, 0.2);
			content: '';
			height: 0;
			left: rh(-8);
			position: absolute;
			right: rh(-8);
			top: 0;
		}
	}

	&-total {
		display: flex;
		flex-direction: column;
		margin-right: rh(10);

		@include media(md-down) {
			margin-bottom: rh(6);
		}
	}

	&-total_label {
		font-weight: bold;
	}

	&-total_price {
		font-size: 18px;
	}

	&-label {
		color: $color-red;
		margin-top: rh(2);
	}

	&-cta {
		display: flex;

		@include media(lg-up) {
			justify-content: space-between;
		}

		@include media(md-down) {
			flex-direction: column;
		}

		.b-button {
			height: auto;
			line-height: 24px;
			min-height: 40px;
			white-space: normal;
		}

		&.m-related_items {
			justify-content: center;
			width: 100%;
		}
	}

	&-baby_registry_cta {
		margin-right: rh(5);
		min-width: 220px;

		@include media(md-down) {
			margin-bottom: rh(4);
			margin-right: 0;
		}
	}
}
