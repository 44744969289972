.b-product_set_detail {
	&-title {
		font-size: 24px;
		margin-bottom: 20px;
	}

	&-subtitle {
		margin-bottom: 15px;
	}
}
