.b-product_availability {
	&-msg {
		margin-top: rh(6);
	}

	.b-product_status,
	.b-product_status.m-pre_order {
		text-align: left;
	}
}

.availability-product-style {
	bottom: rh(-9.5);
	position: relative;

	.b-product_status {
		span {
			background-color: $color-white;
		}
	}
}
