.b-product_promotions {
	&-item {
		font-size: 16px;
	}

	&-item + &-item {
		margin-top: rh(6);
	}

	&-callout {
		color: var(--color-brand);
		font-weight: bold;

		.b-theme_rle & {
			font-weight: 500;
		}

		.l-cart & {
			margin-bottom: 10px;
		}
	}

	&-btn {
		background: none;
		border: 0;
		color: $color-brand;
		cursor: pointer;
		font: 16px/1.2 $font-sans;
		margin-top: rh(1);
		padding: 0;

		&:hover {
			text-decoration: underline;
		}
	}
}
