.b-product_description {
	font-size: 16px;
	line-height: 1.375;

	.b-theme_hmv & {
		ol {
			list-style: auto;
			padding-left: 18px;
		}
	}
}
