.b-product_recommendations {
	&-list {
		display: flex;
	}

	&-control {
		cursor: pointer;
		display: flex;
	}

	&-control_name {
		align-self: center;
		padding: rh(6 2);
		text-align: center;
		width: 100%;
	}

	&-control_active {
		background-color: $color-white;
		color: $color-text;
		cursor: default;
		font-weight: bold;
		transition: $motion-ease;
		transition-property: background-color, color;
	}

	&-item {
		display: none;
		font-size: 14px;
		line-height: 1.2;
	}

	&-item_shown {
		display: block;
	}
}
