.b-product_related_tabs {
	@include g-tabs;

	&-list {
		@include g-tabs(_tab_list);
	}

	&-tab {
		@include media(sm) {
			flex-grow: 1;
			text-align: center;
		}
	}

	&-control {
		@include g-tabs(_tab);

		@include media(sm) {
			justify-content: center;
			padding-left: rh(3);
			padding-right: rh(3);
		}

		&[aria-selected='true'] {
			@include g-tabs(_tab_active);
		}

		.b-product_tabs-tab_list_item:first-child & {
			@include g-tabs(_tab_first);
		}
	}

	&-content {
		@include g-tabs(_content);

		&[aria-hidden='false'] {
			@include g-tabs(_content_shown);
		}
	}
}
