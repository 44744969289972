.b-product_age {
	&-item {
		font-size: 16px;
	}

	&-item ~ &-item {
		margin-top: rh(5);
	}

	&-btn {
		background: none;
		border: 0;
		color: $color-brand;
		cursor: pointer;
		font: 16px/1.2 $font-sans;
		margin-top: rh(1);
		padding: 0;

		&:hover {
			text-decoration: underline;
		}
	}

	&-esrb {
		display: inline-block;
		max-width: 85%;
	}

	&-esrb_icon {
		float: left;

		/* stylelint-disable */
		&.m-esrb_Everyone,
		&.m-esrb_Everyone_10,
		&.m-esrb_Teen,
		&.m-esrb_Mature,
		&.m-esrb_Adults_Only,
		&.m-esrb_Rating_Pending,
		&.m-esrb_Enfants_et_adultes,
		&.m-esrb_Enfants_et_adultes_10,
		&.m-esrb_Adolescents,
		&.m-esrb_Jeunes_adultes,
		&.m-esrb_Cote_en_attente,
		&.m-esrb_Seulment_les_adultes {
			background-size: 36px 50px;
			height: 50px;
			margin-top: rh(2);
			width: 36px;
		}

		&.m-esrb_Everyone,
		&.m-esrb_Enfants_et_adultes {
			background-image: url('../../img/ratings/E.svg');
		}

		&.m-esrb_Everyone_10,
		&.m-esrb_Enfants_et_adultes_10 {
			background-image: url('../../img/ratings/E10plus.svg');
		}

		&.m-esrb_Teen,
		&.m-esrb_Adolescents {
			background-image: url('../../img/ratings/T.svg');
		}

		&.m-esrb_Mature,
		&.m-esrb_Jeunes_adultes {
			background-image: url('../../img/ratings/M.svg');
		}

		&.m-esrb_Adults_Only,
		&.m-esrb_Seulment_les_adultes {
			background-image: url('../../img/ratings/AO.svg');
		}

		&.m-esrb_Rating_Pending,
		&.m-esrb_Cote_en_attente {
			background-image: url('../../img/ratings/RP.svg');
		}
		/* stylelint-enable */
	}

	&-txt {
		display: inline-block;
		font-weight: bold;
		padding: rh(4) 0;
	}

	&-esrb_txt {
		color: $color-blue;
		display: inline-block;
		font-weight: bold;
		margin-top: rh(1);
		padding-left: rh(2);

		.b-theme_hmv & {
			color: var(--color-brand);
		}
	}

	&-esrb_rating {
		display: block;
		padding-left: rh(2);
	}

	.b-modal-header {
		padding-bottom: 0;
	}

	.b-modal-title {
		margin: 0;
	}

	&-modal_title {
		font: 24px/1.2 $font-serif;
		margin-bottom: rh(5);
		text-transform: uppercase;

		.b-theme_rle & {
			letter-spacing: 0.03em;
		}
	}

	&-modal_content {
		line-height: 22px;
		padding-bottom: rh(3);

		p {
			padding-bottom: rh(3);
		}
	}
}
