$pinch-icon: 'data:image/svg+xml;charset=utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23FFF%22%20fill-rule%3D%22evenodd%22%20d%3D%22M17.927%2016.532a.64.64%200%200%200-.096.266l-.341%202.642c-.041.311-.3.559-.607.559h-6.468a.623.623%200%200%201-.549-.364c-.409-.852-1.464-3.037-1.965-3.797-.812-1.23-2.899-1.825-4.545-2.913-1.434-.947-.704-2.701.592-2.701.191%200%20.395.038.606.124.945.379%201.853.781%202.697%201.239.182.099.368.143.553.143.623%200%201.185-.513%201.185-1.223V1.419c0-.947.695-1.42%201.387-1.42.692%200%201.386.473%201.386%201.42v3.695a.624.624%200%200%200%20.712.621%202.28%202.28%200%200%201%20.35-.027c.496%200%201.074.169%201.518.794a.604.604%200%200%200%20.65.232%202.4%202.4%200%200%201%20.614-.083c.515%200%201.121.188%201.572.899a.595.595%200%200%200%20.473.275c.618.034%201.952.337%202.268%202.272.3%201.834-.209%203.645-1.992%206.435zM6.805%204.206a.511.511%200%200%201-.504-.518V1.886l-4.5%204.658h1.755a.51.51%200%200%201%20.504.517.51.51%200%200%201-.504.518H.532A.544.544%200%200%201%200%207.052V4.034c0-.287.225-.519.504-.519.278%200%20.504.232.504.519v1.822L5.667%201.11H3.892a.511.511%200%200%201-.504-.517c0-.286.226-.518.504-.518h2.941c.278%200%20.476.222.476.508v3.105a.511.511%200%200%201-.504.518z%22%2F%3E%0A%3C%2Fsvg%3E';

.b-product_zoom {
	/* stylelint-disable */
	// Bootstrap photoswipe styles override
	.pswp__bg {
		background: linear-gradient(to bottom, $color-white 700px, rgba(0, 0, 0, 0.5) 700px);
		transform: translateZ(0);
	}

	.pswp__scroll-wrap {
		max-height: 700px;
	}

	.pswp__top-bar,
	.pswp__ui--fit .pswp__top-bar {
		background-color: transparent;
		opacity: 1;
	}

	.pswp__ui--hidden {
		.pswp__top-bar {
			opacity: 1;
		}
	}

	.pswp__counter {
		display: none;
	}

	.pswp__img {
		background-color: transparent;
		object-fit: contain;
	}

	.pswp__button {
		align-items: center;
		border: solid 1px $color-brand97;
		border-radius: $global-radius;
		box-shadow: $depth-1;
		color: transparent;
		cursor: pointer;
		display: flex;
		font-size: 1px;
		height: 50px;
		justify-content: center;
		opacity: 0.9;
		overflow: hidden;
		position: absolute;
		text-align: center;
		top: 50%;
		transform: translateY(-50%);
		width: 50px;

		&:hover {
			border-color: $color-brand;
		}
	}

	.pswp__button.pswp__button--close {
		border: 0;
		box-shadow: none;
		color: $color-brand76;
		opacity: 1;
		right: 30px;
		top: 50px;
		visibility: visible;

		&:hover {
			color: $color-brand;
		}
	}

	.pswp__button.pswp__button--arrow--left {
		background: url($arrow-left-toys) no-repeat center center $color-white;
		opacity: 1;
		visibility: visible;

		@include media(md-up) {
			left: 140px;
		}

		@include media(xl) {
			left: 270px;
		}

		@include media(sm) {
			display: none;
		}

		&::before {
			display: none;
		}

		.b-theme_babies & {
			background-image: url($arrow-left-babies);
		}

		.b-theme_hmv & {
			background-image: url($arrow-left-hmv);
		}
	}

	.pswp__button.pswp__button--arrow--right {
		background: url($arrow-right-toys) no-repeat center center $color-white;
		opacity: 1;
		visibility: visible;

		@include media(md-up) {
			right: 140px;
		}

		@include media(xl) {
			right: 270px;
		}

		@include media(sm) {
			display: none;
		}

		&::before {
			display: none;
		}

		.b-theme_babies & {
			background-image: url($arrow-right-babies);
		}

		.b-theme_hmv & {
			background-image: url($arrow-right-hmv);
		}
	}

	.pswp__message {
		display: none;

		@include media(md-down) {
			background: url($pinch-icon) no-repeat 25px center;
			background-color: rgba(0, 0, 0, 0.3);
			border-radius: $global-radius;
			color: white;
			display: block;
			font-weight: 900;
			left: 50%;
			padding: rh(3) rh(6) rh(3) 60px;
			position: absolute;
			text-transform: uppercase;
			top: 50%;
			transform: translate( -50%, -50%);
			white-space: nowrap;
			z-index: 1;
		}
	}

	.pswp__dots {
		display: none;

		@include media(sm) {
			bottom: 50px;
			display: flex;
			justify-content: center;
			left: 50%;
			margin: rh(5 0 8);
			position: absolute;
			transform: translateX(-50%);
		}

		button ~ button {
			margin-left: rh(4);
		}

		button {
			background-color: $color-grey91;
			border: 2px solid $color-white;
			border-radius: 50%;
			color: transparent;
			cursor: pointer;
			display: block;
			font-size: 1px;
			height: 12px;
			line-height: 8;
			overflow: hidden;
			padding: 0;
			transition-duration: 0.2s;
			transition-property: background-color, border-color;
			transition-timing-function: ease-in;
			width: 12px;

			&:hover {
				background-color: $color-brand;
				border-color: $color-brand;
			}

			&.active {
				background-color: $color-brand;
				border-color: $color-brand;
			}
		}
	}
	/* stylelint-enable */
}
