.b-product_variation {
	align-items: center;
	display: flex;
	position: relative;

	&-group {
		display: flex;
		flex-direction: column;
		padding: 10px;
		width: 75%;
	}

	&-label {
		display: block;
		font-size: 14px;
		margin-bottom: rh(2);
		width: 25%;

		&.m-colors {
			margin-bottom: 0;
		}
	}

	&-swatches {
		display: flex;
		flex-wrap: wrap;
		margin-top: rh(0 -2);

		.b-color_swatch {
			border-radius: 50%;
			margin-top: rh(1.5);

			@include media(md) {
				margin: rh(2);
			}

			&-img {
				border: 1px solid var(--color-brand);
				border-radius: 50%;
				height: 30px;
				max-width: 30px;
				width: 30px;
			}
		}
	}

	&-error_message {
		color: $color-red;
		font: 14px/1.29 $font-sans;
		margin-top: 10px;
		text-transform: uppercase;
	}

	.b-ellipsis {
		align-self: end;
		font-weight: bold;
	}
}
