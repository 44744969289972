.b-product_tabs {
	@include media(md-up) {
		@include g-tabs;
	}

	@include media(sm) {
		margin: rh(0 -4);
	}

	&-tab_list {
		@include media(md-up) {
			@include g-tabs(_tab_list);
		}

		@include media(sm) {
			display: none;
		}
	}

	&-tab_list_item {
		margin: 0;
		padding: 0;
	}

	&-tab {
		@include media(md-up) {
			@include g-tabs(_tab);

			&[aria-selected='true'] {
				@include g-tabs(_tab_active);
			}

			.b-product_tabs-tab_list_item:first-child & {
				@include g-tabs(_tab_first);
			}
		}

		.b-theme_rle & {
			font-weight: 500;
		}
	}

	&-accordion_control {
		@include media(md-up) {
			display: none;
		}

		@include media(sm) {
			@include g-accordion-styles(_control);

			border-bottom: 1px solid $color-brand88;
			color: $color-brand;
			font-size: 14px;
			font-weight: bold;
			padding: rh(4 4 3);

			&[aria-expanded='true'] {
				@include g-accordion-styles(_control_expanded);

				border-bottom: none;
			}
		}

		/*TODO fix accordeon and apply styles below*/
		// .b-product_set_detail & {
		// 	@include g-accordion-styles(_control);

		// 	border-bottom: 1px solid $color-brand88;
		// 	border-top: 1px solid $color-brand88;
		// 	color: $color-brand;
		// 	font-size: 14px;
		// 	font-weight: bold;
		// 	padding: rh(4 4 3);

		// 	@include media(md-up) {
		// 		display: flex;
		// 	}

		// 	&[aria-expanded='true'] {
		// 		@include g-accordion-styles(_control_expanded);

		// 		border-bottom: none;
		// 	}
		// }
	}

	&-contents {
		@include media(sm) {
			border-top: 1px solid $color-brand88;
			margin-top: -1px;
		}
	}

	&-content {
		@include media(md-up) {
			@include g-tabs(_content);

			&[aria-hidden='false'] {
				@include g-tabs(_content_shown);
			}
		}

		@include media(sm) {
			@include g-accordion-styles(_content);

			&.m-expanded {
				@include g-accordion-styles(_content_expanded);

				border-bottom: 1px solid $color-brand88;
			}
		}

		/*TODO fix accordeon and apply styles below*/

		/*TODO fix accordeon and apply styles below*/
		// .b-product_set_detail & {
		// 	@include media(md-up) {
		// 		@include g-accordion-styles(_content);

		// 		display: block;
		// 		padding: 8px 16px 28px;

		// 		&.m-expanded {
		// 			@include g-accordion-styles(_content_expanded);

		// 			border-bottom: 1px solid $color-brand88;
		// 		}
		// 	}
		// }
	}

	// Merge 2 tabs into single accordion
	&.m-no_space {
		@include media(sm) {
			margin-bottom: 0;
		}
	}
}
