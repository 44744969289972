.b-product_quantity {
	display: flex;
	flex-direction: column;

	&-label {
		display: flex;
		font-size: 14px;
		justify-content: space-between;
		margin: rh(6 0 2);
	}

	&-label_aside {
		color: $color-grey65;
	}

	&-label_text {
		position: relative;

		&::after {
			content: ':';
			position: absolute;
			right: rh(-1);
		}
	}
}
